







































































































































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { IUserState } from '@store/modules/user/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { ProjectTasksActions } from '@store/modules/project-tasks/Types';
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { IAddTaskPostData } from '@store/modules/project-tasks/interfaces/ApiRequests';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTasks = namespace('storeProjectTasks');
const NSDocuments = namespace('storeProjectDocuments');
const NSProjectDocuments = namespace('storeProjectDocuments');
const NSTasks = namespace('storeProjectTasks');

@Component({
    name: 'DocumentsPopups',
    components: {
        PopupComments: () => import('@components/popups/PopupComments.vue'),
        PopupSignature: () => import('@components/popups/PopupSignature.vue'),
        PopupTasksList: () => import('@components/popups/PopupTasksList.vue'),
        PopupAgreement: () => import('@components/popups/PopupAgreement.vue'),
        PopupCreateTask: () => import('@components/popups/PopupCreateTask.vue'),
        PopupAddDocument: () => import('@components/popups/PopupAddDocument.vue'),
        PopupTaskDetails: () => import('@components/popups/PopupTaskDetails.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        PopupDocumentsList: () => import('@components/popups/PopupDocumentsList.vue'),
        PopupSetDocsStatus: () => import('@components/popups/PopupSetDocsStatus.vue'),
        DocumentsPopups: () => import('@pages/the-documents/components/DocumentsPopups.vue'),
        PopupAddCompositeFolder: () => import('@components/popups/PopupAddCompositeFolder.vue'),
        PopupDocumentsSignature: () => import('@components/popups/PopupDocumentsSignature.vue'),
        PopupDeleteCompositeFolder: () => import('@components/popups/PopupDeleteCompositeFolder.vue'),
    }
})

export default class DocumentsPopups extends Vue {

    $refs!: {
        popupComments: HTMLFormElement,
        popupSignature: HTMLFormElement,
        popupTasksList: HTMLFormElement,
        popupAgreement: HTMLFormElement,
        documentsPopups: HTMLFormElement,
        popupCreateTask: HTMLFormElement,
        popupTaskDetails: HTMLFormElement,
        popupAccessDenied: HTMLFormElement,
        popupDocumentsList: HTMLFormElement,
        popupConfirmRemove: HTMLFormElement,
        popupSetDocsStatus: HTMLFormElement,
        popupCreateDocument: HTMLFormElement,
        popupAddCompositeFolder: HTMLFormElement,
        popupDocumentsSignature: HTMLFormElement,
        popupSuccessNotification: HTMLFormElement,
        popupConfirmRemoveSubtask: HTMLFormElement,
        popupDeleteCompositeFolder: HTMLFormElement,
        popupConfirmMovingToFolder: HTMLFormElement,
    }

    @Prop({}) tasksStore!: any[];

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS) getProjectTasks!: (projectId: string | number) => Promise<void>;
    @NSUser.Getter('isAdminOnCurrentProject') isAdminOnCurrentProject!: boolean;
    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSProject.Getter('projectId') projectId!: number;
    @NSTasks.Action(ProjectTasksActions.A_CREATE_NEW_TASK)
    sendAddTaskFormAction!: ({ postData: IAddTaskPostData }) => Promise<any>
    @NSTasks.Action(ProjectTasksActions.A_DELETE_TASK)
    deleteTask!: (payload: { appId: string | number, ids: string[] }) => Promise<void>
    @NSDocuments.Action(ProjectDocumentsActions.A_REMOVE_DOCUMENT)
    removeDocuments!: (payload: { appId: number; id: string; }) => Promise<void>;
    @NSDocuments.Getter('selectedDocuments') selectedDocumentsStore!: string[];
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_DOCUMENT_PARENT) setDocumentParent!: (payload: any) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;

    popupSuccessNotificationTitle: string = '';
    popupSuccessNotificationText: string = '';
    popupSuccessNotificationOpen: boolean = false;
    showSuccessNotificationPopupDuration: number = 3000;
    popupSignatureDisplayed: boolean = false;
    docDataUrl: string = '';
    closePopups: boolean = false;
    openedPopupTaskDetails: boolean = false;
    selectedTaskToView = {} as ITask;
    timeForReloadSubtasksList: number = 500;
    selectedSubtask = {} as ITask;
    confirmRemoveSubtaskPopupShow: boolean = false;
    deletionConfirmationTextSubtask: string = '';
    openedDocumentRowId: string | number = '';
    confirmRemovePopupShow: boolean = false;
    selectedDocuments: IDocument[] = [];
    accessDeniedPopupShow: boolean = false;
    accessDeniedText: string = '';
    documentsToFolder: string[] = [];
    typeOfItemsMoveToFolder: string = '';
    confirmMovingToFolderText: string = '';
    folderForDocumentsMove: string = '';
    folderForDocumentsMoveTitle: string = '';
    confirmMovingToFolderShow: boolean = false;
    agreementPopupOpen: boolean = false;

    @Watch('confirmMovingToFolderShow')
    watchConfirmMovingToFolderShow() {
        if (!this.confirmMovingToFolderShow) {
            this.confirmMovingToFolderText = '';
        }
    }

    get disabledEscKey(): boolean {
        return this.openedPopupTaskDetails;
    }

    get newTaskDetailsSliderInfo(): ITask {
        const selectedTaskId: string = this.selectedTaskToView.uuid;
        return this.tasksStore.filter((task) => task.uuid === selectedTaskId)[0];
    }

    get selectedDocumentsText() {
        let text: string | number = this.selectedDocuments.length;

        if (this.selectedDocuments.length === 1) {
            text = this.selectedDocuments[0].title;
        }

        return text;
    }

    addDocumentSuccessNotify() {
        this.successNotificationPopupShow(
            'Документ добавлен',
            'Новый документ был успешно добавлен',
        )
        this.reloadDocuments();
    }

    reloadDocuments() {
        this.$emit('reload-documents');
    }

    reloadPackPDList() {
        this.$emit('reload-pack-pd-list');
    }

    successNotificationPopupShow(title: string, text: string, timeForReading = this.showSuccessNotificationPopupDuration) {
        this.popupSuccessNotificationTitle = title;
        this.popupSuccessNotificationText = text;
        this.popupSuccessNotificationOpen = true;

        setTimeout(() => {
            this.successNotificationPopupReset();
        }, timeForReading);
    }

    successNotificationPopupReset() {
        this.popupSuccessNotificationTitle = '';
        this.popupSuccessNotificationText = '';
        this.popupSuccessNotificationOpen = false;
    }

    deleteSubtaskOpenConfirmPopup(subtask: ITask): void {
        this.selectedSubtask = subtask;
        this.deletionConfirmationTextSubtask = `Вы уверены, что хотите удалить подзадачу "${subtask.title}"?`;
        this.confirmRemoveSubtaskPopupShow = true;
    }

    openSignaturePopup(generalData) {
        const data = generalData.docData;
        const fileId = generalData.fileId;
        this.popupSignatureDisplayed = true;
        this.getDocFiles({
            appId: this.projectId,
            documentId: data.uuid,
        }).then((files) => {
            setTimeout(() => {
                data.files = files;
                let selectedFileObj = null;
                if (data.files && data.files.length && fileId) {
                    data.files.forEach(fileObj => {
                        if (fileObj.uuid === fileId) {
                            selectedFileObj = JSON.parse(JSON.stringify(fileObj));
                            this.docDataUrl = fileObj.linkView;
                        }
                    })
                }
                if (data.files && data.files.length && !fileId) {
                    this.docDataUrl = data.files[0].linkView;
                }
                this.$nextTick(() => {
                    if (this.$refs.popupSignature) {
                        this.$refs.popupSignature.changePopupData({
                            allData: data,
                            selectedFile: selectedFileObj,
                        });
                        this.$refs.popupSignature.openPopup();
                    }
                });
            }, 100);
        });
    }

    openPopupSetDocsStatus(popupData) {
        this.$refs.popupSetDocsStatus.openPopup(popupData);
    }

    signSuccessNotify(type = 'files') {
        this.successNotificationPopupShow(
            type === 'file' ? 'Файлы подписаны' : 'Документы подписаны',
            type === 'file' ? 'Все файлы выбранного документа были успешно подписаны.' : 'Выбранные документы были успешно подписаны.',
        );
        setTimeout(() => {
            this.reloadDocuments();
        }, this.showSuccessNotificationPopupDuration);
    }

    onSignedDocuments(data) {
        if (this.agreementPopupOpen) {
            this.$refs.popupAgreement.setSigns(data);
        } else {
            this.signSuccessNotify('docs');
        }
    }

    openCreatePopup() {
        if (this.$refs.popupCreateDocument) {
            this.closeOtherPopup('popupCreateDocument');
            this.$refs.popupCreateDocument.openPopup();
        }
    }

    showUnfinishedTasks(tasks: ITask[]): void {
        if (tasks !== this.$refs.popupTasksList.tasksList) {
            this.$refs.popupTasksList.setTasksList(tasks);
            this.$refs.popupTasksList.openPopup();
        }
        this.closeOtherPopup('popupTasksList');
    }

    closeOtherPopup(currentPopupNameRef) {
        for (let popup in this.$refs) {
            if (currentPopupNameRef !== popup) {
                this.$refs[popup]?.$refs?.basePopup.checkClosePopup();
            }
        }
        this.closePopups = !this.closePopups;
        this.$emit('change-close-popups', this.closePopups);
    }

    openCommentsPopup(data) {
        this.$refs.popupComments.openPopup();
        this.$refs.popupComments.setProjectId(String(data.projectId));
        this.$refs.popupComments.setDocumentId(String(data.documentId));
        this.$refs.popupComments.setGroupTitle(String(data.documentRowTitle));
        this.$refs.popupComments.setDocumentTitle(data.documentTitle);
    }

    openDocumentsList(data) {
        this.getDocFiles({
            appId: this.projectId,
            documentId: data.docData.uuid,
        }).then((files) => {
            data.documents = files;
            if (!this.$refs.popupDocumentsList.documentData) {
                this.$refs.popupDocumentsList.openPopup();
            }

            this.$refs.popupDocumentsList.setDocuments(data);
            this.closeOtherPopup('popupDocumentsList');
        });
    }

    openDocumentsSignature(data) {
        this.$refs.popupDocumentsSignature.setData(data);
        this.$refs.popupDocumentsSignature.openPopup();
    }

    setPackPDSignData(data){
        this.$refs.popupDocumentsSignature.setPackPDSignData(data);
    }

    openAgreementPopup(data) {
        this.$refs.popupAgreement.setData(data);
        this.$refs.popupAgreement.checkActionsAndComments();
    }

    openTaskDetails(task: ITask): void {
        if (this.checkUserRights(task)) {
            this.selectedTaskToView = task;
            this.openedPopupTaskDetails = true;
        } else {
            this.successNotificationPopupShow('Ошибка доступа', 'У Вас нет прав доступа для просмотра данной задачи');
        }
    }

    checkUserRights(task: ITask): boolean {
        let accessAllowed: boolean = false;
        const userId: string = this.userData?.userInfo?.id;
        if (this.isAdminOnCurrentProject || task.author?.uuid === userId || task.executor?.uuid === userId) {
            accessAllowed = true;
        }
        return accessAllowed;
    }

    updateTaskDetailsSlider(): void {
        setTimeout(() => {
            this.getProjectTasks(this.projectId).then(() => {
                this.selectedTaskToView = this.newTaskDetailsSliderInfo;
            });
        }, this.timeForReloadSubtasksList + 100);
    }

    addSubtask(taskFormData: IAddTaskPostData): void {
        this.sendAddTaskFormAction({ postData: taskFormData }).then(() => {
            setTimeout(() => {
                this.updateTaskDetailsSlider();
            }, this.timeForReloadSubtasksList + 100);
            this.successNotificationPopupShow('Добавление подзадачи', 'Подзадача добавлена');
        });
    }

    onConfirmDeleteSubtask(): void {
        this.confirmRemoveSubtaskPopupShow = false;
        this.deleteSubtask();
    }

    deleteSubtask(): void {
        this.deleteTask({
            appId: this.projectId,
            ids: [this.selectedSubtask.uuid],
        }).then(() => {
            this.updateTaskDetailsSlider();
            this.successNotificationPopupShow('Удаление подзадачи', `Подзадача "${this.selectedSubtask.title}" успешно удалена`);
        })
    }

    confirmCreateTask(): void {
        this.successNotificationPopupShow(
            'Задача создана',
            'Задача успешно поставлена',
        );
        this.$emit('reload-tasks');
    }

    openCreateTaskPopup(documentData) {
        this.openedDocumentRowId = documentData.uuid;
        this.$refs.popupCreateTask.openPopup();
        this.$refs.popupCreateTask.setTitle(documentData.title);
    }

    confirmAddCompositeFolder(nameFolder) {
        this.successNotificationPopupShow('Добавление группы', `Группа "${nameFolder}" добавлена`);
        this.reloadDocuments();
    }

    openAddFolderPopup() {
        this.closeOtherPopup('popupAddCompositeFolder');
        this.$refs.popupAddCompositeFolder.openPopup();
    }

    openDeleteFolderPopup() {
        this.closeOtherPopup('popupDeleteCompositeFolder');
        this.$refs.popupDeleteCompositeFolder.openPopup();
    }

    confirmDeleteCompositeFolder(folder) {
        this.successNotificationPopupShow(`Удаление папки`, `Папка "${folder.title}" удалена`);
        this.reloadDocuments();
    }

    onSuccessSetDocsStatus() {
        this.successNotificationPopupShow(`Изменение статуса`, `Статус выбранного(ых) документа(ов) успешно изменен`);
        setTimeout(() => {
            this.reloadDocuments();
        }, this.showSuccessNotificationPopupDuration);
    }

    onSuccessAgreement() {
        this.successNotificationPopupShow(`Согласование`, `Согласование комплекта документов завершено`);
        setTimeout(() => {
            this.reloadPackPDList();
            this.reloadDocuments();
        }, this.showSuccessNotificationPopupDuration);
    }

    setConfirmRemovePopupShow(val: boolean) {
        this.confirmRemovePopupShow = val;
    }

    checkBeforeDelete(documents) {
        let canBeDelete: boolean = true;
        this.selectedDocuments = documents;
        this.selectedDocuments.forEach((doc) => {
            if (doc.signed) {
                canBeDelete = false;
                this.accessDeniedText = 'Вы не можете удалить документ, т.к. в нем содержатся подписанные файлы';
            }
            if (!this.ifAuthor(doc) && !this.isAdminOnCurrentProject) {
                canBeDelete = false;
                this.accessDeniedText = 'Вы не можете удалить документ, т.к. не являетесь его автором или владельцем проекта';
            }
            if (doc.category === 'Предписание' && !this.userRightOnlyGSN) {
                canBeDelete = false;
                this.accessDeniedText = 'Создавать, редактировать и удалять документы категории "Предписание" может только инспектор ГСН';
            }
        })
        if (canBeDelete) {
            this.setConfirmRemovePopupShow(true);
        } else {
            this.setAccessDeniedPopupShow(true);
        }
    }

    onConfirmDeleteDocuments() {
        const promises = this.selectedDocuments
            .map((document) => this.removeDocuments({appId: this.projectData.id, id: document.uuid}))
        Promise.all(promises)
            .then(() => this.deleteDocumentSuccessNotify());
    }

    setAccessDeniedPopupShow(val: boolean) {
        this.accessDeniedPopupShow = val;
    }

    ifAuthor(doc) {
        return doc.author && doc.author.uuid && doc.author.uuid === this.userData.userInfo.id;
    }

    deleteDocumentSuccessNotify() {
        this.successNotificationPopupShow(
            'Документы удалены',
            'Выбранные вами документы были успешно удалены',
        )
        this.reloadDocuments();
    }

    setAccessDeniedPopupShowEdit(data) {
        this.accessDeniedText = data.accessDeniedText;
        this.setAccessDeniedPopupShow(data.accessDeniedPopupShow);
    }

    closePopupConfirmation() {
        this.popupSuccessNotificationOpen = false;
    }

    showPopupConfirmAddDocsToFolder(e) {
        this.typeOfItemsMoveToFolder = e.elementType;
        if (this.typeOfItemsMoveToFolder && this.typeOfItemsMoveToFolder === 'document') {
            let itemsCount = 1;
            let itemsForMove = [e.dropElementId];
            if (this.selectedDocumentsStore && this.selectedDocumentsStore.length) {
                itemsCount = this.selectedDocumentsStore.length;
                itemsForMove = this.selectedDocumentsStore;
            }
            this.confirmMovingToFolderText = `Переместить документы (${itemsCount} шт.) в папку "${e.dropTargetTitle}"?`;
            this.confirmMovingToFolderShow = true;
            this.documentsToFolder = itemsForMove;
            this.folderForDocumentsMove = e.dropTargetId;
            this.folderForDocumentsMoveTitle = e.dropTargetTitle;
        } else if (this.typeOfItemsMoveToFolder
            && this.typeOfItemsMoveToFolder === 'composite'
            && e.dropTargetId !== e.dropElementId) {
            this.confirmMovingToFolderText = `Переместить выбранную папку в папку "${e.dropTargetTitle}"?`;
            this.confirmMovingToFolderShow = true;
            this.documentsToFolder = [e.dropElementId];
            this.folderForDocumentsMove = e.dropTargetId;
            this.folderForDocumentsMoveTitle = e.dropTargetTitle;
        }
    }

    confirmAddDocsToFolder() {
        this.setDocumentParent({
            appId: this.projectData.id,
            documentId: this.documentsToFolder,
            parentId: this.folderForDocumentsMove,
        }).then(() => {
            if (this.typeOfItemsMoveToFolder && this.typeOfItemsMoveToFolder === 'document') {
                this.successNotificationPopupShow(
                    'Документы перемещены',
                    `Документы (${this.documentsToFolder.length} шт.) успешно перемещены в папку "${this.folderForDocumentsMoveTitle}"`,
                )
            } else if (this.typeOfItemsMoveToFolder && this.typeOfItemsMoveToFolder === 'composite') {
                this.successNotificationPopupShow(
                    'Папка перемещена',
                    `Выбранная папка успешно перемещены в папку "${this.folderForDocumentsMoveTitle}"`,
                )
            }

            setTimeout(() => {
                this.reloadDocuments();
                this.confirmMovingToFolderShow = false;
                this.documentsToFolder = [];
                this.folderForDocumentsMove = '';
                this.folderForDocumentsMoveTitle = '';
                this.typeOfItemsMoveToFolder = '';
            }, this.showSuccessNotificationPopupDuration);
        });
    }
}
